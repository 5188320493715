<template>
<form @submit.prevent="onAddDriver" data-vv-scope="driver">
  <v-card-title
      class="lighten-4 py-4 title"
  >
    {{driver_id !== null ? 'Edit' : 'Add'}} driver
  </v-card-title>
  <template v-if="myLoading">
    <v-card-text>
      Please stand by
      <v-progress-linear
        indeterminate
        color="primary"
        class="mb-0"
      ></v-progress-linear>
    </v-card-text>
  </template>
  <template v-else>
    <v-container grid-list-sm class="pa-4">
      <v-row>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="name"
            v-model="form.name"
            type="text"
            v-validate="{ required: true, regex: /^[ A-Za-z'-]*$/ }"
            data-vv-name="name"
            :error="errors.has('driver.name')"
            :error-messages="errors.first('driver.name')"
            name="name"
            id="name"
            :autofocus="true"
            label="Name"
            placeholder="Name"
            hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-text-field
              :disabled="!permissions.DRIVERS_ALL"
              ref="second_driver"
              v-model="form.second_driver"
              type="text"
              v-validate="{ regex: /^[ A-Za-z'-]*$/ }"
              data-vv-name="second_driver"
              :error="errors.has('driver.second_driver')"
              :error-messages="errors.first('driver.second_driver')"
              name="second_driver"
              id="second_driver"
              :autofocus="true"
              label="Second Driver's Name"
              placeholder="Second Driver's Name"
              hide-details="auto"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="4">
          <v-combobox
            clearable
            :value="form.owner"
            :items="owners"
            return-object
            item-text="name"
            item-id="owner"
            item-disabled="disabled"
            label="Owner"
            hide-details="auto"
            hide-no-data
            :loading="loading"
            placeholder="Start typing to Search"
            prepend-icon="mdi-database-search"
            name="owners"
            @input="onSelectInputEvent('owner', $event)"
          >
            <template v-slot:item="{ item }">
                <span :class="{ 'is-company': item.is_company }">
                  {{ item.name }}
                  <span v-if="item.is_company">Third party</span>
                </span>
              </template>
          </v-combobox>
        </v-col>
        <v-col
          cols="12"
          sm="2"
          md="2"
        >
          <v-select :items="boards"
            :disabled="!permissions.DRIVERS_ALL"
            v-model="selected.board"
            label="Board"
            item-value="_id"
            item-text="name"
            return-object
            v-validate="'required'"
            data-vv-name="board"
            :error="errors.has('driver.board')"
            :error-messages="errors.first('driver.board')"
            hide-details="auto"
            @change="onBoardSelect()"
          ></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="5"
          md="5"
          >
            <v-autocomplete :items="users"
              :disabled="!permissions.DRIVERS_ALL"
              v-model="selected.user"
              :value="selected.user"
              label="Dispatcher"
              item-value="_id"
              item-text="name"
              v-validate="'required'"
              data-vv-name="user"
              :error="errors.has('driver.user')"
              :error-messages="errors.first('driver.user')"
              hide-details="auto"
              @change="onUsersSelect"
              clearable
              return-object></v-autocomplete>
          </v-col>

          <v-col
          cols="12"
          sm="5"
          md="5"
          >
            <v-autocomplete :items="usersNew"
              :disabled="!permissions.DRIVERS_ALL || selected.user === null"
              v-model="form.substitute_user"
              :value="form.substitute_user"
              label="Substitute Dispatcher"
              item-value="_id"
              item-text="name"
              hide-details="auto"
              :search-input.sync="searchUsers"
              :loading="autocompleteLoading"
              @change="onSubstituteUsersSelect"
              clearable
              return-object>
              <template v-slot:no-data> Type to search users </template>
            </v-autocomplete>
          </v-col>
        <v-col
          cols="12"
          sm="4"
          md="4"
        >
          <v-select :items="fleetManagers"
            :disabled="true"
            v-model="selected.fleetManager"
            :value="selected.fleetManager"
            label="Fleet Manager"
            item-value="_id"
            item-text="name"
            hide-details="auto"
            clearable
            hint="This field is automatic"
            persistent-hint
            return-object></v-select>
        </v-col>

        <v-col
          cols="12"
          sm="4"
          md="4"
        >
          <v-select
            :items="payrolles"
            :disabled="!permissions.PAYROLL"
            v-model="selected.payroll"
            :value="selected.payroll"
            label="Payroll"
            item-value="_id"
            item-text="name"
            hide-details="auto"
            clearable
            return-object></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="4"
          md="4"
        >
          <v-select
            :items="trailerManagers"
            :disabled="!permissions.DRIVERS_ALL"
            v-model="selected.trailer_manager"
            :value="selected.trailer_manager"
            label="Trailer manager"
            item-value="_id"
            item-text="name"
            hide-details="auto"
            clearable
            return-object></v-select>
        </v-col>
        <v-col
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="dispatcher_ext"
            v-model="form.dispatcher_ext"
            label="Dis. ext"
            name="dispatcher_ext"
            id="dispatcher_ext"
            hide-details="auto"
            placeholder="Dispatcher EXT"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="dispatcher_ext_second"
            v-model="form.dispatcher_ext_second"
            label="Dis. ext 2"
            name="dispatcher_ext_second"
            id="dispatcher_ext_second"
            hide-details="auto"
            placeholder="Dispatcher EXT second"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="dispatcher_ext_third"
            v-model="form.dispatcher_ext_third"
            label="Dis. ext 3"
            name="dispatcher_ext_third"
            id="dispatcher_ext_third"
            hide-details="auto"
            placeholder="Dispatcher EXT third"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="3" md="3">
          <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="dispatcher_ext_third"
            v-model="form.dispatcher_ext_fourth"
            label="Dis. ext 4"
            name="dispatcher_ext_fourth"
            id="dispatcher_ext_fourth"
            hide-details="auto"
            placeholder="Dispatcher EXT fourth"
          ></v-text-field>
        </v-col>



        <v-col
          cols="12"
          sm="2"
          md="2"
        >
          <!-- <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="company"
            v-model="form.company"
            v-validate="'max:2'"
            label="Company"
            name="company"
            id="company"
            :error="errors.has('driver.company')"
            :error-messages="errors.first('driver.company')"
            hide-details="auto"
            placeholder="Company"
          ></v-text-field> -->
          <v-select
            ref="company"
            v-model="form.company"
            :items="companiesShortAll"
            label="Company"
            name="company"
            id="company"
            hide-details="auto"
            clearable
          >
          </v-select>
        </v-col>

        <v-col
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="phone"
            v-model="form.phone"
            v-validate="'required'"
            data-vv-name="phone"
            label="Phone"
            :error="errors.has('driver.phone')"
            :error-messages="errors.first('driver.phone')"
            name="phone"
            id="phone"
            hide-details="auto"
            placeholder="Phone"
          ></v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="owner_phone"
            v-model="form.owner_phone"
            label="Owner Phone"
            name="owner_phone"
            id="owner_phone"
            hide-details="auto"
            placeholder="Owner Phone"
          ></v-text-field>
        </v-col>

        <v-col
          cols="6"
          sm="2"
          md="2"
        >
          <v-text-field
            ref="lb"
            v-model="form.lb"
            name="lb"
            id="lb"
            label="Lb"
            hide-details="auto"
            placeholder="Lb"
          ></v-text-field>
        </v-col>

        <v-col
          cols="6"
          sm="2"
          md="2"
        >
          <v-text-field
            ref="safety"
            v-validate="'max:1'"
            v-model="form.safety"
            name="safety"
            label="Safety"
            id="safety"
            :error="errors.has('driver.safety')"
            :error-messages="errors.first('driver.safety')"
            hide-details="auto"
            placeholder="Safety"
          ></v-text-field>
        </v-col>

        <v-col
          cols="6"
          sm="4"
          md="4"
        >
          <!-- <v-text-field
            :disabled="!canEditTruckField"
            ref="truck"
            v-model.trim="form.truck"
            data-vv-as="Truck"
            data-vv-name="truck"
            v-validate="'required|max:6'"
            :error="errors.has('driver.truck')"
            :error-messages="errors.first('driver.truck')"
            name="truck"
            id="truck"
            label="Truck"
            hide-details="auto"
            placeholder="Truck"
            @blur="getFleetManager(form.truck)"
          ></v-text-field> -->
          <v-combobox
            :disabled="!canEditTruckField"
            ref="truck"
            :value="form.truck"
            v-validate="'required|max:6'"
            data-vv-as="Truck"
            data-vv-name="truck"
            :error="errors.has('driver.truck')"
            :error-messages="errors.first('driver.truck')"
            hide-details="auto"
            :items="trucks"
            :search-input.sync="searchTrucks"
            :loading="loading"
            label="Truck"
            hide-no-data
            placeholder="Start typing..."
            prepend-icon="mdi-truck"
            clearable
            name="truck"
            id="truck"
            item-text="number"
            item-id="number"
            :return-object="false"
            @input="onSelectInputEvent('truck', $event)"
          ></v-combobox>
        </v-col>

        <v-col
          cols="6"
          sm="5"
          md="5"
        >
          <!-- <v-text-field
            :disabled="!permissions.DRIVERS_ALL"
            ref="trailer"
            v-model="form.trailer"
            v-validate="'required|max:10'"
            data-vv-as="Trailer"
            data-vv-name="trailer"
            :error="errors.has('driver.trailer')"
            :error-messages="errors.first('driver.trailer')"
            name="trailer"
            id="trailer"
            label="Trailer"
            hide-details="auto"
            placeholder="Trailer"
          ></v-text-field> -->
          <v-combobox
            :disabled="!permissions.DRIVERS_ALL"
            ref="trailer"
            :value="form.trailer"
            v-validate="'max:10'"
            data-vv-as="Trailer"
            data-vv-name="trailer"
            :error="errors.has('driver.trailer')"
            :error-messages="errors.first('driver.trailer')"
            hide-details="auto"
            :items="trailers"
            :search-input.sync="searchTrailers"
            :loading="loading"
            label="Trailer"
            hide-no-data
            placeholder="Start typing..."
            prepend-icon="mdi-truck-trailer"
            clearable
            name="trailer"
            id="trailer"
            item-text="number"
            item-id="number"
            :return-object="false"
            @click:clear="onClearTrailer"
            @input="onSelectInputEvent('trailer', $event)"
          ></v-combobox>
        </v-col>

        <v-col
          cols="12"
          sm="3"
          md="3"
        >
          <v-text-field
            :readonly="false"
            ref="type_trailer"
            v-model="form.type_trailer"
            v-validate="'max:10'"
            data-vv-as="Type trailer"
            data-vv-name="type_trailer"
            :error="errors.has('driver.type_trailer')"
            :error-messages="errors.first('driver.type_trailer')"
            name="type_trailer"
            id="type_trailer"
            label="Trailer type"
            hide-details="auto"
            placeholder="Trailer type"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-textarea
            rows="3"
            name="note"
            label="Note"
            v-validate="'max:1000'"
            v-model="form.note"
            id="note"
            :error="errors.has('driver.note')"
            :error-messages="errors.first('driver.note')"
            hint="maximum 1000 characters"
          ></v-textarea>
        </v-col>
        <v-col
          cols="2"
          sm="2"
          md="2"
        >
          <v-switch
            hide-details="auto"
            v-model="form.fc"
          >
            <template v-slot:label>
              <span class="text">FC {{form.fc ? 'ON' : 'OFF'}}</span>
            </template>
          </v-switch>
        </v-col>
        <v-col
            cols="3"
        >
          <v-switch
              hide-details="auto"
              :disabled="!permissions.DRIVERS_ALL"
              v-model="form.is_priority"
              label="Is priority"
          ></v-switch>
        </v-col>
        <v-col
            cols="2"
        >
          <v-switch
              hide-details="auto"
              :disabled="!permissions.DRIVERS_ALL"
              v-model="form.is_new"
              label="Is new"
          ></v-switch>
        </v-col>
        <v-col
            cols="3"
        >
          <v-switch
              hide-details="auto"
              :disabled="!permissions.DRIVERS_ALL"
              v-model="form.is_company"
              label="Is company"
          ></v-switch>
        </v-col>
        <v-col
            cols="2"
        >
          <v-switch
              hide-details="auto"
              :disabled="!permissions.DRIVERS_ALL"
              v-model="form.is_time"
              label="Is time"
          ></v-switch>
        </v-col>
        <v-col
          cols="4"
        >
          <v-checkbox
            hide-details="auto"
            :disabled="!permissions.DRIVERS_ALL"
            v-model="form.is_active"
            label="Is active"
          ></v-checkbox>
          <v-checkbox
              hide-details="auto"
              :disabled="!permissions.DRIVERS_ALL"
              v-model="form.no_cut"
              label="NO CUT"
          ></v-checkbox>
          <v-checkbox
              hide-details="auto"
              :disabled="!permissions.DRIVERS_ALL"
              v-model="form.has_app_acc"
              label="Has an app account?"
          ></v-checkbox>
        </v-col>
        <v-col
          cols="2"
        >
        </v-col>
      </v-row>
    </v-container>
  </template>
  <v-col
    v-if="driver_id"
    cols="12"
    sm="4"
    md="4"
  >
    <v-switch
      hide-details="auto"
      v-model="showTrailerHistory"
    >
      <template v-slot:label>
        <span class="text">Show trailer history</span>
      </template>
    </v-switch>
  </v-col>
  <TrailerHistory v-if="driver_id && showTrailerHistory" type="driver" :id="driver_id" />
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="close">Cancel</v-btn>
    <v-btn color="info" :loading="loading" :disabled="loading || myLoading" type="submit">{{form._id ? 'Save' : 'Add'}}</v-btn>
  </v-card-actions>
</form>
</template>

<script>
import {mapGetters} from 'vuex'
import TrailerHistory from '../Trailer/TrailerHistory.vue'
import debounce from 'lodash/debounce'
import TrailersService from '../../services/TrailersService'
import TrucksService from '../../services/TruckService'
// import DriverService from '../../services/DriverService'
import UsersService from '../../services/UsersService'

export default {
  name: 'DriversForm',
  props: ['driver_id'],
  components: {
    TrailerHistory
  },
  data(){
    return {
      form: {
        name: '',
        second_driver: '',
        board: null,
        user: null,
        substitute_user: null,
        type_trailer: '',
        dispatcher_ext: '',
        dispatcher_ext_second: '',
        dispatcher_ext_third: '',
        dispatcher_ext_fourth: '',
        company: '',
        truck: '',
        trailer: '',
        phone: '',
        owner_phone: '',
        lb: '',
        safety: '',
        note: '',
        is_active: true,
        no_cut: false,
        is_priority: false,
        is_new: false,
        is_company: false,
        is_time: false,
        fc: false,
        has_app_acc: false,
      },
      owners: [],
      users: [],
      fleetManagers: [],
      payrolles: [],
      trailerManagers: [],
      boards: [],
      myLoading: false,
      options: {
        page: 1,
        sortBy: ['_id'],
        sortDesc: [true],
        itemsPerPage: 10000
      },
      selected: {
        user: null,
        board: null,
        fleetManager: null,
        payroll: null,
        trailer_manager: null
      },
      trailers: [],
      trucks: [],
      searchTrailers: '',
      searchTrucks: '',
      showTrailerHistory: false,
      searchOwners: '',
      searchUsers: '',
      usersNew: [],
      autocompleteLoading: false
    }
  },
  computed: {
    ...mapGetters('common', ['loading', 'companiesShortAll']),
    ...mapGetters('auth', ['permissions', 'getRoles']),
    ...mapGetters('dashboard', ['selectedBoard']),
    boardName () {
      let boardName = ''
      for (const board of this.boards)
      {
        if(board._id === this.selectedBoard){
          boardName = board.name
        }
      }
      return boardName
    },
    canEditTruckField() {
      if (this.getRoles && (this.getRoles[0] === 'SUPERADMIN' || this.getRoles[0] === 'ADMIN')) {
        return true
      } else if (this.permissions.DRIVERS_ALL && (this.boardName === 'Rec' || this.boardName === 'Recovery' || this.getRoles[0] === 'TRUCK_DEPARTMENT')) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    'driver_id': function(newId, oldId) {
      if (newId !== oldId && newId !== null)
        this.getDriver(newId)
      this.getOwners()
    },
    searchTrailers (val) {
      if (val) {
        this.debounceSearch(val)
      }
    },
    searchTrucks (val) {
      if (val) {
        this.debounceSearchTrucks(val)
      }
    },
    searchUsers (val) {
      if (val) {
        this.debounceSearchUsers(val)
      }
    }
  },
  methods: {
    onAddDriver() {
      this.$validator.validateAll('driver').then((result) => {
        if (result) {
          if (this.form._id) {
            // NO PERMISSION TO EDIT
            if (!this.permissions.DRIVERS_EDIT) return

            if(this.selected.board){
              this.form.board = this.selected.board
            }

            if(this.selected.user){
              this.form.user = this.selected.user
            }

            if(this.selected.fleetManager){
              this.form.fleetManager = this.selected.fleetManager
            } else {
              this.form.fleetManager = null
            }

            if(this.selected.payroll){
              this.form.payroll = this.selected.payroll
            } else {
              this.form.payroll = null
            }

            if(this.selected.trailer_manager){
              this.form.trailer_manager = this.selected.trailer_manager
            } else {
              this.form.trailer_manager = null
            }

            this.$store.dispatch('drivers/editDriver', this.form).then(res => {
              if (res) {
                this.errors.clear()
                this.form = {}
                this.close()
              }
            })
          } else {
            // NO PERMISSION TO ADD
            if (!this.permissions.DRIVERS_ADD) return

            if(this.selected.board){
              this.form.board = this.selected.board
            }

            if(this.selected.user){
              this.form.user = this.selected.user
            }

            if(this.selected.fleetManager){
              this.form.fleetManager = this.selected.fleetManager
            } else {
              this.form.fleetManager = null
            }

            if(this.selected.payroll){
              this.form.payroll = this.selected.payroll
            } else {
              this.form.payroll = null
            }

            if(this.selected.trailer_manager){
              this.form.trailer_manager = this.selected.trailer_manager
            } else {
              this.form.trailer_manager = null
            }

            this.$store.dispatch('drivers/addDriver', this.form).then(res => {
              if (res) {
                this.errors.clear()
                this.form = {}
                this.close()
              }
            })
          }
        }
      })
    },
    getUsers(){
      this.$store.dispatch('user/getUsers', this.options).then(res => {
        this.users = res.docs
      })
    },
    getFleetManagers(){
      this.$store.dispatch('user/getUsersByRoleId', 11).then(res => {
        this.fleetManagers = res
      })
    },
    getPayrolles(){
      this.$store.dispatch('user/getUsersByRoleId', 17).then(res => {
        this.payrolles = res
      })
    },
    getTrailerManagers(){
      this.$store.dispatch('user/getUsersByRoleId', 9).then(res => {
        this.trailerManagers = res
      })
    },
    getUsersByBoard(boardId){
      this.$store.dispatch('user/getUsersByBoardId', boardId).then(res => {
        this.users = res
      })
    },
    getBoards(){
      this.$store.dispatch('boards/getAllBoards').then(res => {
        this.boards = res
      })
    },
    close(){
      this.$emit('onClose')
      this.showTrailerHistory = false
      this.errors.clear()
    },
    onBoardSelect(){
      this.getUsersByBoard(this.selected.board._id)
    },
    onUsersSelect(){
      if (this.selected.user) {
        this.form.dispatcher_ext = this.selected.user.ext
        this.form.dispatcher_ext_second = this.selected.user.ext_second
        this.form.dispatcher_ext_third = this.selected.user.ext_third
        this.form.dispatcher_ext_fourth = this.selected.user.ext_fourth
      }
    },
    onSubstituteUsersSelect(){
      // clear string after main extension
      this.form.dispatcher_ext = this.form.dispatcher_ext ? this.form.dispatcher_ext.replace(/\(.*\)/, '') : ''
      this.form.dispatcher_ext_second = this.form.dispatcher_ext_second ? this.form.dispatcher_ext_second.replace(/\(.*\)/, '') : ''
      this.form.dispatcher_ext_third = this.form.dispatcher_ext_third ? this.form.dispatcher_ext_third.replace(/\(.*\)/, '') : ''
      this.form.dispatcher_ext_fourth = this.form.dispatcher_ext_fourth ? this.form.dispatcher_ext_fourth.replace(/\(.*\)/, '') : ''
      if (this.form.substitute_user) {
        this.form.dispatcher_ext += this.form.substitute_user.ext ? ` (${this.form.substitute_user.ext})` : ''
        this.form.dispatcher_ext_second += this.form.substitute_user.ext_second ? ` (${this.form.substitute_user.ext_second})` : ''
        this.form.dispatcher_ext_third += this.form.substitute_user.ext_third ? ` (${this.form.substitute_user.ext_third})` : ''
        this.form.dispatcher_ext_fourth += this.form.substitute_user.ext_fourth ? ` (${this.form.substitute_user.ext_fourth})` : ''
      }
    },
    getDriver(id) {
      this.myLoading = true
      this.$store.dispatch('drivers/getDriver', id).then(res => {
        this.getUsersByBoard( res.data.board._id)
        this.selected.board = res.data.board
        this.selected.user = res.data.user
        this.selected.fleetManager = res.data.fleetManager
        this.selected.payroll = res.data.payroll
        this.selected.trailer_manager = res.data.trailer_manager
        this.form._id = res.data._id
        this.form.name = res.data.name
        this.form.second_driver = res.data.second_driver
        this.form.owner = res.data.owner
        this.form.board = res.data.board
        this.form.user = res.data.user
        this.form.substitute_user = res.data.substitute_user
        this.form.payroll = res.data.payroll
        this.form.trailer_manager = res.data.trailer_manager
        this.form.company = res.data.company
        this.form.type_trailer = res.data.type_trailer
        this.form.dispatcher_ext = res.data.dispatcher_ext
        this.form.dispatcher_ext_second = res.data.dispatcher_ext_second
        this.form.dispatcher_ext_third = res.data.dispatcher_ext_third
        this.form.dispatcher_ext_fourth = res.data.dispatcher_ext_fourth
        this.form.truck = res.data.truck
        this.form.trailer = res.data.trailer
        this.form.phone = res.data.phone
        this.form.owner_phone = res.data.owner_phone
        this.form.lb = res.data.lb
        this.form.fc = res.data.fc
        this.form.safety = res.data.safety
        this.form.note = res.data.note
        this.form.is_active = res.data.is_active
        this.form.no_cut = res.data.no_cut
        this.form.is_priority = res.data.is_priority
        this.form.is_new = res.data.is_new
        this.form.is_company = res.data.is_company
        this.form.is_time = res.data.is_time
        this.form.has_app_acc = res.data.has_app_acc
        this.myLoading = false

        if (res.data.substitute_user) {
          this.usersNew.push(res.data.substitute_user)
        }

        this.$validator.validateAll('driver').then((result) => {
          if (result) {
            console.log('validate again')
          }
        })
      })
    },
    debounceSearch: debounce( function(search) {
      if (search && search.length) {
        this.$store.dispatch('common/setLoading', true)
        TrailersService.searchTrailers(search).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data) {
            this.trailers = res.data
          }
        }).catch(err => {
          console.log('trailers error search', err)
          this.trailers = []
        })
      } else {
        this.trailers = []
      }
    }, 400),
    debounceSearchTrucks: debounce( function(search) {
      if (search && search.length) {
        this.$store.dispatch('common/setLoading', true)
        TrucksService.searchTruck(search).then(res => {
          this.$store.dispatch('common/setLoading', false)
          if (res.data) {
            this.trucks = res.data
          }
        }).catch(err => {
          console.log('trailers error search', err)
          this.trucks = []
        })
      } else {
        this.trucks = []
      }
    }, 400),
    debounceSearchUsers: debounce( function(search) {
      if (search && search.length) {
        this.autocompleteLoading = true
        UsersService.getUsersAutocomplete(search).then(res => {
          this.autocompleteLoading = false
          if (res.data) {
            this.usersNew = res.data
          }
        }).catch(err => {
          console.log('trailers error search', err)
          this.usersNew = []
        })
      }
    }, 400),
    onClearTrailer() {
      this.form.type_trailer = ''
    },
    // STARA logika za selektovanje FM kada se promeni truck
    // getFleetManager(truck) {
    //   if (truck && truck.length) {
    //     this.$store.dispatch('common/setLoading', true)
    //     DriverService.getDriverFmByTruck(truck).then(res => {
    //       this.$store.dispatch('common/setLoading', false)
    //       if (res.data.status === 'OK' && res.data.driver && res.data.driver.fleetManager) {
    //         this.selected.fleetManager = res.data.driver.fleetManager
    //       }
    //     }).catch(err => {
    //       console.log('trailers error search', err)
    //     })
    //   }
    // },
    getOwners() {
      this.$store.dispatch('trailers/getAllOwners').then(res => {
        this.owners = res
      })
    },
    onSelectInputEvent(field, event) {
      this.form[field] = event
    }
  },
  mounted() {
    this.getFleetManagers()
    this.getPayrolles()
    this.getTrailerManagers()
    this.getOwners()
    this.$store.dispatch('common/getAllCompanies')
    if (this.permissions.DRIVERS_ALL) {
      this.getBoards()
    }
    if (this.driver_id !== null) {
      this.getDriver(this.driver_id)
    }
  },
  destroyed() {
    this.form = {}
  }
}
</script>
