import Api from '@/services/Api'

export default {
  getUsers (pagination) {
    return Api().get('/users', {params: {
      page: pagination.page,
      perPage: pagination.itemsPerPage,
      sortby: pagination.sortBy,
      order: pagination.descending ? 1 : -1,
      search: pagination.search,
      role_id: pagination.role_id
    }})
  },
  getRoles (pagination) {
    return Api().get('/roles', {params: {
      page: pagination.page || 1,
      perPage: pagination.itemsPerPage || 50 ,
      sortby: pagination.sortDesc || 'role_id',
      order: pagination.sortDirection || 1
    }})
  },
  addRole (role) {
    return Api().post('/roles', role)
  },
  editRole (role) {
    return Api().put('/roles', role)
  },
  deleteRole (id) {
    return Api().delete(`/roles/${id}`)
  },
  getAllPermissions () {
    return Api().get('/roles/permissions')
  },
  getUsersByBoardId(boardId) {
    return Api().get(`/users/get-users-by-board/${boardId}`)
  },
  updateUsers(users) {
    return Api().put('/users/update-order', {users})
  },
  getUsersByRoleId(roleId) {
    return Api().get(`/users/get-users-by-role/${roleId}`)
  },
  getUsersMention(search) {
    return Api().get(`/users/mention/${search}`)
  },
  getUsersAutocomplete(search) {
    return Api().get(`/users/auto-complete/${search}`)
  },
  addUser (user) {
    return Api().post('/users', user)
  },
  inviteUser (user) {
    return Api().post('/users/invite', user)
  },
  createNewLink (id) {
    return Api().get(`/users/invite-new-link/${id}`)
  },
  activateUser (data) {
    return Api().post('/users/activate', data)
  },
  activateAppUser (data) {
    return Api().post('/v2/drivers-app/validate-mail', data)
  },
  resetPassword (email) {
    return Api().post('/users/forgot-password', {email: email})
  },
  updateUser (user) {
    return Api().put('/users', user)
  },
  updateUserPassword (id) {
    return Api().put(`/users/update-password/${id}`)
  },
  deleteUser (id) {
    return Api().delete(`/users/${id}`)
  },
  deleteUserPermanent (id) {
    return Api().delete(`/users/delete-permanent/${id}`)
  },
  getByRole(term, role) {
    return Api().get(`/users/by-role/${role}/${term}`)
  },
  getUserTokens (id) {
    return Api().get(`/users/user-tokens/${id}`)
  },

  // FND USERS
  getFndUsers (pagination) {
    return Api().get('/users/fnd-users-all', {
      params: {
        page: pagination.page,
        perPage: pagination.itemsPerPage,
        search: pagination.search,
      }})
  },
  async getFndUserById(id) {
    return Api().get(`/users/fnd-user-by-id/${id}`)
  },

  addFndUser(userData) {
    return Api().post('users/add-fnd-user', userData)
  },
  editFndUser(userData) {
    return Api().put('users/edit-fnd-user', userData)
  },
  deleteFndUser (id) {
    return Api().delete(`/users/deactivate-fnd-user/${id}`)
  },
  deletefndUserPermanent (id) {
    return Api().delete(`/users/delete-permanent-fnd-user/${id}`)
  },

  getFmGroupedByBoard(data) {
    return Api().get('/users/fleet-managers-grouped-by-boards', { params: data })
  },
}
