import user from '../../services/UsersService'

const state = {
  users: [],
  boardUsers:[],
  roleUsers: [],
  userTokens: []
}

const getters = {
  users (state) {
    return state.users
  },
  boardUsers (state) {
    return state.boardUsers
  },
  getUserById: (state) => (id) => {
    return state.users.find(user => user._id === id)
  }
}

const actions = {
  getUsers ({commit, dispatch}, payload) {
    dispatch('common/setLoading', true, {root: true})
    return user.getUsers(payload).then(res => {
      commit('setUsers', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getUsersByRoleId({commit, dispatch}, payload){
    dispatch('common/setLoading', true, {root: true})
    return user.getUsersByRoleId(payload).then(res => {
      commit('setUsersByRoleId', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getUsersByBoardId ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return user.getUsersByBoardId(id).then(res => {
      commit('setUsersByBoardId', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  addUser ({commit, dispatch}, userData) {
    dispatch('common/setLoading', true, {root: true})
    let formatedRoles = []
    for (let role of userData.roles) {
      formatedRoles.push({
        _id: role._id,
        role_id: role.role_id,
        role_name: role.role_name,
        name: role.name,
        path: role.path ? role.path : null,
        permissions: role.permissions ? role.permissions : null
      })
    }
    userData.roles = formatedRoles
    return user.addUser(userData).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addUser', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  inviteUser ({commit, dispatch}, userData) {
    dispatch('common/setLoading', true, {root: true})
    let formatedRoles = []
    for (let role of userData.roles) {
      formatedRoles.push({
        _id: role._id,
        role_id: role.role_id,
        role_name: role.role_name,
        name: role.name,
        path: role.path ? role.path : null,
        permissions: role.permissions ? role.permissions : null
      })
    }
    userData.roles = formatedRoles
    return user.inviteUser(userData).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('addUser', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  createNewLink ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return user.createNewLink(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('updateUser', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  updateUser ({commit, dispatch}, userData) {
    dispatch('common/setLoading', true, {root: true})
    let formatedRoles = []
    for (let role of userData.roles) {
      formatedRoles.push({
        _id: role._id,
        role_id: role.role_id,
        role_name: role.role_name,
        name: role.name,
        path: role.path ? role.path : null,
        permissions: role.permissions ? role.permissions : null
      })
    }
    userData.roles = formatedRoles
    return user.updateUser(userData).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('updateUser', res.data)
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  updateUserPassword ({dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return user.updateUserPassword(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  deleteUser ({commit, dispatch}, id) {
    dispatch('common/setLoading', true, {root: true})
    return user.deleteUser(id).then(res => {
      dispatch('common/setLoading', false, {root: true})
      commit('deleteUser', id)
      return Promise.resolve(res)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
  getUserTokens({commit, dispatch}, payload){
    return user.getUserTokens(payload).then(res => {
      commit('setUserTokens', res.data.docs)
      dispatch('common/setLoading', false, {root: true})
      return Promise.resolve(res.data)
    }).catch(err => {
      dispatch('common/setLoading', false, {root: true})
      return Promise.reject(err)
    })
  },
}

const mutations = {
  setUsers (state, users) {
    state.users = users
  },
  setUsersByBoardId(state,users){
    state.boardUsers = users
  },
  setUsersByRoleId(state,users){
    state.roleUsers = users
  },
  addUser (state, user) {
    const tmpUsers = state.users.slice()
    tmpUsers.push(user)
    state.users = tmpUsers
  },
  updateUser (state, user) {
    let tmpUsers = state.users.slice()
    let index = tmpUsers.findIndex(el => el._id === user._id)
    tmpUsers.splice(index, 1, user)
    state.users = tmpUsers
  },
  deleteUser (state, id) {
    const tmpUsers = state.users.slice()
    tmpUsers.splice(tmpUsers.findIndex(el => el._id === id), 1)
    state.users = tmpUsers
  },
  setUserTokens(state,tokens){
    state.userTokens = tokens
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
